import React from 'react'
import {
  Box,
  Text,
  CreateValueCodeLink,
  CardLink,
  H2,
  H3,
} from '../../components'
import { products_products } from '../../core-types'
import { FormattedCurrency } from '../../components/FormattedCurrency'

const Product = ({ product }: { product: products_products }) => {
  const priceComponent = product.priceWithoutVat !== null &&
    product.priceWithoutVat !== undefined && (
      <FormattedCurrency value={product.priceWithoutVat} />
    )
  return (
    <CreateValueCodeLink productId={product.id}>
      <CardLink>
        <Box>
          <H2 mb={0} typoStyle="sm" color="text" pr="sm">
            {product.name}
          </H2>

          <H3 mb={0} typoStyle="sm" color="primaryText">
            {priceComponent}
          </H3>

          {product.description && (
            <Text as="p" mt="xs" mb="xs" color="text">
              {product.description}
            </Text>
          )}
        </Box>
      </CardLink>
    </CreateValueCodeLink>
  )
}

export default Product
