import React, { FC } from 'react'
import { useIntl, FormattedNumber } from 'react-intl'

type Props = {
  value: number
  precision?: number
}

function getPrecision(value: number): number {
  return value % 1 === 0 ? 0 : 2
}

export const FormattedCurrency: FC<Props> = ({
  value,
  precision = getPrecision(value),
}) => {
  const intl = useIntl()
  const currency =
    intl.formats && intl.formats.number && intl.formats.number.currency.currency

  return (
    <FormattedNumber
      value={value}
      style="currency"
      currency={currency}
      minimumFractionDigits={precision}
      maximumFractionDigits={precision}
    />
  )
}
