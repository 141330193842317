import React from 'react'
import Layout from '../components/Layout'
import Dashboard from '../modules/Dashboard'

const DashboardPage = () => {
  return (
    <Layout
      loading={false}
      contentProps={{
        // bg: 'primary',
        mx: 0,
        mb: 0,
        py: 0,
      }}
      render={() => {
        return <Dashboard />
      }}
    />
  )
}

export default DashboardPage
