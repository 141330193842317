import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Text,
  H1,
  H2,
  Container,
  Flex,
  Grid,
  GridCell,
  Box,
  Link,
  Anchor,
} from '../../components'
import { ProductsQuery, StatisticsQuery } from '../../lib/core'
import { globalMessages } from '../../lib/messages'
import Product from './Product'

const Dashboard = () => {
  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      minHeight="100vh"
      position="relative"
      overflow="hidden"
    >
      <Box bg="primary">
        <Container>
          <H1 textAlign="center" color="primaryContrast" mb="md" mt="lg">
            <FormattedMessage
              id="modules.Dashboard.title"
              defaultMessage="Share a ride!"
            />
          </H1>
          <Grid justifyContent="center">
            <ProductsQuery>
              {({ data }) => {
                if (!data || !data.products) {
                  return null
                }
                return (
                  <>
                    {data.products.map((product) => {
                      return (
                        <GridCell
                          key={product.id}
                          width={[1, 1, 1, 1 / 2, 1 / 3, 1 / 3]}
                          mb="sm"
                        >
                          <Product product={product} />
                        </GridCell>
                      )
                    })}
                  </>
                )
              }}
            </ProductsQuery>
          </Grid>
          <Text color="primaryContrast" textAlign="center" mb="sm" mt="sm">
            <FormattedMessage {...globalMessages.allPricesWithoutVAT} />
          </Text>
        </Container>
      </Box>
      <Container variant="narrow" my="md">
        <StatisticsQuery>
          {({ data }) => {
            if (!data || !data.statistics) {
              return null
            }

            const { claimedCodes, sharedCodes } = data.statistics

            return (
              <>
                <H2 typoStyle="md" mb="xs">
                  <FormattedMessage
                    id="modules.Dashboard.codes.title"
                    defaultMessage="You have"
                  />
                </H2>
                <Grid mb="sm">
                  {!isNaN(sharedCodes) && (
                    <GridCell width={[1, 1, 1 / 2]}>
                      <Text typoStyle="lg" color="primary">
                        {sharedCodes}
                      </Text>
                      <FormattedMessage
                        id="modules.Dashboard.sharedLabel"
                        defaultMessage="Shared codes"
                      />
                    </GridCell>
                  )}
                  {!isNaN(claimedCodes) && (
                    <GridCell width={[1, 1, 1 / 2]}>
                      <Text typoStyle="lg" color="primary">
                        {claimedCodes}
                      </Text>
                      <FormattedMessage
                        id="modules.Dashboard.claimedLabel"
                        defaultMessage="Claimed codes"
                      />
                    </GridCell>
                  )}
                </Grid>
                <Link href="/value-codes">
                  <Anchor>
                    <FormattedMessage
                      id="modules.Dashboard.allCodesLink"
                      defaultMessage="See all codes"
                    />
                  </Anchor>
                </Link>
              </>
            )
          }}
        </StatisticsQuery>
      </Container>
    </Flex>
  )
}

export default Dashboard
